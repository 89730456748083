<template>
  <form
    class="searchform"
    @submit.stop.prevent="submit"
  >
    <input
      v-model="search"
      type="text"
      name="searchinput"
      class="searchinput"
      :placeholder="$t('search.title')"
    >
    <button
      type="submit"
      class="searchsubmit"
      name="searchsubmit"
      :color="buttonColor"
    >
      <img
        src="@/assets/search-icon.png"
        :alt="$t('search.submit')"
      >
    </button>
  </form>
</template>

<script>
export default {
  name: 'Searchform',
  props: {
    buttonColor: { type: String },
    value: { type: String }
  },
  data: () => ({
    loading: false,
    search: null
  }),
  mounted() {
    this.search = this.value
  },
  methods: {
    submit() {
      this.$emit('input', this.search)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.searchform {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: relative;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }
}
.searchinput {
  font-weight: 300;
  color: #3E3E3D;
  padding: 15px;
  min-width: 240px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: inset 1px 1px 5px #00000059;
  height: 60px;
  font-size: 18px;
  font-family: inherit;
  @include media-breakpoint-up(lg) {
    min-width: 280px;
  }
  &:focus-visible {
    outline: transparent;
  }

}
.searchsubmit {
  background-color: var(--yellow-color);
  height: 60px;
  border: none;
  box-shadow: 1px 1px 3px #0000008c;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  min-width: 70px;
  max-width: 80px;
}
.searchsubmit[color="red"] {
  background-color: var(--red-color);
}
.searchsubmit[color="green"] {
  background-color: var(--green-color);
}
.searchsubmit[color="purple"] {
  background-color: var(--purple-color);
}
.searchsubmit[color="medium-purple"] {
  background-color: var(--medium-purple-color);
}
.searchsubmit[color="yellow"] {
  background-color: var(--yellow-color);
}
.searchsubmit img {
  max-height: 60%;
}
</style>
